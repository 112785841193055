import getSpaceId from "utils/constants/getSpaceId";
import { instance } from "./instances";
import { AxiosResponse } from "axios";

export const GoalsAPI = {
  getGoals: () => instance.get(`/my_spaces/${getSpaceId()}/my_goals/`),
  createGoal: (data: { title: string; goal: number }) =>
    instance.post(`/my_spaces/${getSpaceId()}/create_goal/`, data),
  deleteGoal: (goalId: number) =>
    instance.delete(`/my_spaces/${getSpaceId()}/delete_goal/${goalId}/`),
  transferToGoal: (data: TransferToGoalReq) =>
    instance.put(`/my_spaces/${getSpaceId()}/transfer/`, {
      from_object: "account",
      to_object: "goal",
      ...data,
    }),
  editGoal: ({
    goalId,
    title,
    goal,
  }: { goalId: number; title: string; goal: number }): Promise<AxiosResponse> =>
    instance.put(`/my_spaces/${getSpaceId()}/my_goals/${goalId}/`, {
      title,
      goal,
    }),
};
