import React, { useState, lazy, Suspense } from "react";
import MonthsContainer from "./Months";

import classes from "../home_page.module.scss";

import Loader from "utils/components/Loader/Loader";
import PremiumMenu from "./PremiumMenu";
const CreateCategory = lazy(() => import("./CreateCategory/PC"));

import useStore from "store";

// Images
import MenuOpenBtnSvg from "/src/image/p_menu/MenuOpenBtn.svg";
import Notifications from "./Notifications/Notifications";

interface MenuOpenBtnProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  menuPos: { top: string; left: string };
}

const MenuOpenBtn = ({ setShowMenu, menuPos }: MenuOpenBtnProps) => {
  return (
    <button
      type="button"
      id="MenuOpenBtn"
      className={`
        left-6 top-6
        !h-[3rem] !w-[3rem] rounded-md
        border-0 bg-[#19191A] p-2
      `}
      onClick={() => setShowMenu(true)}
    >
      <img src={MenuOpenBtnSvg} alt="MenuOpenBtn" className="h-full w-full" />
    </button>
  );
};

const Overlay = () => {
  const { showDialog } = useStore((state) => state);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuPos: { left: string; top: string } = {
    left: "6",
    top: "6",
  };
  // menuPos какого-то хера работает только на половину. Потому, пока от него отказываюсь

  return (
    <div className={classes.overlay}>
      <MonthsContainer />
      <Notifications />
      {showMenu ? (
        <PremiumMenu setShowMenu={setShowMenu} menuPos={menuPos} />
      ) : (
        <MenuOpenBtn setShowMenu={setShowMenu} menuPos={menuPos} />
      )}
      {showDialog && (
        <Suspense fallback={<Loader />}>
          <CreateCategory />
        </Suspense>
      )}
    </div>
  );
};

export default Overlay;
