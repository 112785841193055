import { z } from "zod";
import { arrayIcons } from "utils/constants/categoryIcons";

export const titleObj = z
  .string()
  .max(24, { message: "The title must be up to 24 characters long" })
  .min(2, { message: "The title must be at least 2 characters long" })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The title contains prohibited characters",
  });

import { currenciesNames } from "utils/constants/currencies";

export const currencyObj = z
  .string()
  .refine((value) => currenciesNames.includes(value), {
    message: "It`s non correct currency",
  })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The currency contains prohibited  characters",
  });

export const categoryIconsObj = z.enum(arrayIcons);

export const commentObj = z
  .string()
  .max(100, { message: "The comment must be up to 100 characters long" })
  //a small step towards xss protection
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The comment contains prohibited characters",
  });
