import { z } from "zod";

const getLength = (arr: string[] | null) => (arr ? arr.length : 0);

export const valSchema = z.object({
  "E-mail": z
    .string()
    .email({ message: "This is not a valid email" })
    .max(50, "this email is too long")
    .min(5, "this email is too short")
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The email contains prohibited characters",
    })
    .optional(),
  Password: z
    .string()
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The password contains prohibited characters",
    })
    .refine(
      (value) => {
        if (value === "") {
          return true;
        }
        const letters: string[] | null = value.match(/[a-zA-Z]/g);
        const numbers: string[] | null = value.match(/[0-9]/g);
        const special: string[] | null = value.match(/[^a-zA-Z0-9]/g);
        return (
          getLength(letters) >= 4 &&
          getLength(numbers) >= 3 &&
          getLength(special) >= 1
        );
      },
      {
        message:
          "The password must contain at least 4 letters, 3 numbers and 1 special character",
      },
    )
    .optional(),
  username: z
    .string()
    .max(30, { message: "Your name must be up to 30 characters long" })
    .min(2, { message: "Your name must be at least 2 characters long" })
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "Your name contains prohibited characters",
    }),
});
