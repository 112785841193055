import { useState } from "react";
import type { OneAccount } from "utils/types/response/AccountRes";
import edit__logo from "image/Account/EditLogo.svg";
import { createPortal } from "react-dom";
import Modal from "utils/components/Modal";
import EditAccountContainer from "./EditAccount";
import Incoming from "./Transactions/Incoming";
import Outgoing from "./Transactions/Outgoing";

const ShowAccount = ({
  currencySymbol,
  Account,
  disabled,
}: {
  currencySymbol: string;
  Account: OneAccount;
  disabled: boolean;
}) => {
  const [editingAccount, setEditingAccount] = useState<boolean>(false);
  const [showFullBalance, setShowFullBalance] = useState<boolean>(false);
  return (
    <>
      <div className="text-white w-[90px] flex h-full mt-[90px] flex-col justify-center items-center">
        <div
          onMouseLeave={() => setShowFullBalance(false)}
          onMouseEnter={() => setShowFullBalance(true)}
          className="relative bg-gradient-to-t flex flex-col items-center from-[#ff5c02] to-[#ff7528] w-full text-center rounded-[7px]"
        >
          {showFullBalance && (
            <div className="!absolute border border-[#252525] -top-6 bg-[#19191A] px-2 rounded-[7px]">
              {currencySymbol + Number.parseFloat(Account.balance)}
            </div>
          )}
          <p className="text-[15px]">
            {Account?.title.length <= 14
              ? Account.title
              : `${Account.title.slice(0, 12)}...`}
          </p>
          <p className="text-[12px]">
            {currencySymbol + Account?.balance_converted}
          </p>
        </div>

        <div className="bg-[#2D2D2E] mt-0.5 p-1 items-center grid grid-cols-[minmax(0,1fr)_minmax(0,4fr)] grid-rows-[repeat(2,auto_5px)] w-full rounded-[7px]">
          <Incoming
            disabled={disabled}
            currencySymbol={currencySymbol}
            Account={Account}
          />
          <Outgoing
            disabled={disabled}
            currencySymbol={currencySymbol}
            Account={Account}
          />
        </div>
        <button
          className="bg-gradient-to-t disabled:cursor-not-allowed from-[#ff5c02] to-[#ff7528] p-0.5 mt-[55px] rounded"
          type="button"
          onClick={() => setEditingAccount(true)}
          disabled={disabled}
        >
          <img src={edit__logo} alt="edit button" className="w-5" />
        </button>
      </div>
      {editingAccount &&
        createPortal(
          <Modal setActive={setEditingAccount}>
            <EditAccountContainer
              setModal={setEditingAccount}
              Account={Account}
            />
          </Modal>,
          document.body
        )}
    </>
  );
};

export default ShowAccount;
