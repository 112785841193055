import ConfirmEmailContainer from "components/HomePage/Overlay/SettingsWindow/ConfirmEmail";
import { Navigate, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";

const getEmailsFromUrl = () => {
  const [searchParams] = useSearchParams();
  const oldEmail: string | null = DOMPurify.sanitize(
    searchParams.get("oldEmail"),
  );
  const newEmail: string | null = DOMPurify.sanitize(
    searchParams.get("newEmail"),
  );
  return [oldEmail, newEmail];
};

const MobileSettings = () => {
  window.ReactNativeWebView?.postMessage("Here");
  const [oldEmail, newEmail] = getEmailsFromUrl();
  if (
    encodeURIComponent(window.location.pathname).includes("mobile-settings") &&
    !window.ReactNativeWebView
  ) {
    return <Navigate to="/" />;
  }
  return (
    <div className="w-dvw h-dvh flex justify-center items-center">
      <ConfirmEmailContainer
        oldEmail={oldEmail}
        setConfirmingEmail={(val) => {
          if (!val) window.ReactNativeWebView?.postMessage("Done");
        }}
        newEmail={newEmail}
      />
    </div>
  );
};

export default MobileSettings;
