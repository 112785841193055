import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import styles from './space_list.module.scss';
import { SpaceListResponse, CircleItem } from "utils/types/response/SpaceResponse";
import { Link } from 'react-router-dom';

import SettingsIcon from 'image/spaces_p/Settings.svg';
import OneUserIcon from 'image/spaces_p/OneUserIcon.svg';
import TwoUsersIcon from 'image/spaces_p/TwoUsersIcon.svg';
import ThreeUsersIcon from 'image/spaces_p/ThreeUsersIcon.svg';
import AngleRightIcon from 'image/spaces_p/AngleRightIcon.svg';
import AngleLeftIcon from 'image/spaces_p/AngleLeftIcon.svg';
import PlusIcon from 'image/spaces_p/Plus.svg';
import { sanitizeObject } from "utils/functions/sanitizeObject";

const SpaceList = () => {
  const [spaces, setSpaces] = useState<SpaceListResponse[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const plusButtons = [
    { id: 'create1', title: '+' },
    { id: 'create2', title: '+' }
  ];

  const circleItems: CircleItem[] = [
    plusButtons[0],
    ...spaces.map((space) => ({
      id: space.id.toString(),
      title: space.title,
      currency: space.currency,
      members_count: space.members_count,
    })),
    plusButtons[1],
  ];

  const getVisibleItems = (index: number) => {
    const start = Math.max(index - 2, 0);
    const end = Math.min(index + 2, circleItems.length - 1);
    return circleItems.slice(start, end + 1);
  };

  useEffect(() => {
    SpaceAPI.getMySpaces()
      .then((spaces) => {
        setSpaces(spaces);
        const storedSelectedIndexValue = localStorage.getItem("selectedIndex");
        const storedSelectedIndex = storedSelectedIndexValue ? parseInt(storedSelectedIndexValue, 10) : 0;
        if (!isNaN(storedSelectedIndex) && storedSelectedIndex < circleItems.length) {
          setSelectedIndex(storedSelectedIndex);
        }
      })
      .catch((error) => {
        console.error("Error fetching spaces:", error);
      });
  }, [circleItems.length]);

  const handleCircleSelect = (index: number) => {
    setSelectedIndex(index);
    localStorage.setItem("selectedIndex", index.toString());
  };

  const handleOpenSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && selectedItem.id.startsWith('create')) {
      navigate("/space/create");
    } else {
      const selectedSpace = spaces.find((space) => selectedItem && space.id.toString() === selectedItem.id);
      if (selectedSpace) {
        localStorage.setItem("space", JSON.stringify(sanitizeObject(selectedSpace)));
        navigate("/");
      }
    }
  };

  const handlePrevSpace = () => {
    setSelectedIndex((prevIndex) => (prevIndex === 0 ? circleItems.length - 1 : prevIndex - 1));
  };

  const handleNextSpace = () => {
    setSelectedIndex((prevIndex) => (prevIndex === circleItems.length - 1 ? 0 : prevIndex + 1));
  };

  const handleEditSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && !selectedItem.id.startsWith('create')) {
      navigate(`/space/edit/${selectedItem.id}`);
    }
  };

  const handleLeaveSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && !selectedItem.id.startsWith('create')) {
      const spaceId = parseInt(selectedItem.id, 10);
      SpaceAPI.leaveFromSpace(spaceId)
        .then(() => {
          setSpaces(spaces.filter(space => space.id !== spaceId));
          setSelectedIndex(prevIndex =>
            prevIndex >= circleItems.length - 2 ? 0 : prevIndex
          );
        })
        .catch((error) => {
          console.error("Error leaving space:", error);
        });
    }
  };

  const getIconForMembers = (count: number) => {
    if (count === 1) return OneUserIcon;
    if (count === 2) return TwoUsersIcon;
    return ThreeUsersIcon;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
        handlePrevSpace();
        break;
      case 'ArrowRight':
        handleNextSpace();
        break;
      case 'Enter':
        handleOpenSpace();
        break;
      default:
        break;
    }
  };

  const visibleItems = getVisibleItems(selectedIndex);

  return (
    <div className={styles.spaceListPage} onKeyDown={handleKeyDown} tabIndex={0}>
      <div className={styles.titleBody}>
        <Link to="/" className={styles.title}>← Home</Link>
        <div className={styles.spaceList}>
          <div className={styles.spaceListHeader}>
            <div className={styles.spaceCircleContainer}>
              {visibleItems.map((item) => {
                const itemIndex = circleItems.indexOf(item);
                let fadeClass = "";

                if (itemIndex === selectedIndex - 1 || itemIndex === selectedIndex + 1) {
                  fadeClass = styles.fadeLevel1;
                } else if (itemIndex === selectedIndex - 2 || itemIndex === selectedIndex + 2) {
                  fadeClass = styles.fadeLevel2;
                }

                return (
                  <div
                    key={item.id}
                    className={`${styles.spaceCircle} ${itemIndex === selectedIndex ? styles.selected : ""} ${fadeClass} ${
                      item.id.startsWith("create") ? styles.plusButton : ""
                    }`}
                    onClick={() => handleCircleSelect(itemIndex)}
                  >
                    {item.id.startsWith("create") ? (
                      <img src={PlusIcon} alt="Plus" className={styles.plusIcon} />
                    ) : (
                      <img src={getIconForMembers(item.members_count as number)} alt={`${item.members_count} users`}
                           className={styles.spaceIcon} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {spaces.length > 0 && selectedIndex > 0 && selectedIndex < circleItems.length - 1 && (
            <div className={styles.spaceInfo}>
              <div className={styles.spaceTitleContainer}>
                <span className={styles.spaceTitle}>{spaces[selectedIndex - 1].title}</span>
                <button className={styles.editButton} onClick={handleEditSpace}>
                  <img src={SettingsIcon} alt="Edit" className={styles.settingsIcon} />
                </button>
              </div>
              <span className={styles.membersCount}>{spaces[selectedIndex - 1].members_count} members</span>
              <div className={styles.currencyWrapper}>
                <span className={styles.currency}>{spaces[selectedIndex - 1].currency}</span>
              </div>
            </div>
          )}
          <div className={styles.pageIndicators}>
            {circleItems.map((item, index) => (
              <div
                key={index}
                className={`${styles.pageIndicator} ${index === selectedIndex ? styles.active : ""}`}
              ></div>
            ))}
          </div>
          <div className={styles.spaceActions}>
            <div className={styles.spaceActionBtns}>
              <button onClick={handlePrevSpace} className={styles.spaceActionBtnCircle}>
                <img src={AngleLeftIcon} alt="Previous" />
              </button>
              <div className={styles.actionButtonsContainer}>
                {!circleItems[selectedIndex]?.id.startsWith('create') && (
                  <span
                    className={styles.leaveSpaceText}
                    onClick={handleLeaveSpace}
                  >
          Leave Space
        </span>
                )}
                <button
                  className={styles.spaceActionBtn}
                  disabled={spaces.length === 0}
                  onClick={handleOpenSpace}
                >
                  {circleItems[selectedIndex]?.id.startsWith('create') ? 'Create' : 'Come in'}
                </button>
              </div>
              <button onClick={handleNextSpace} className={styles.spaceActionBtnCircle}>
                <img src={AngleRightIcon} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceList;