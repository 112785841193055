import cl from "./FormChoose.module.scss";
import { type FC, useEffect } from "react";

interface IProps {
  isLogining: boolean;
  setIsLogining: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormChoose: FC<IProps> = ({ isLogining, setIsLogining }) => {
  const settingSubmitButton = () => {
    const buttons = document
      .querySelector(`.${cl.form_choose}`)
      ?.querySelectorAll("button");
    buttons?.forEach((button) => button.setAttribute("type", "button"));
    const choosedButton = document.querySelector(`.${cl.active_choose}`);
    choosedButton?.setAttribute("type", "submit");
  };

  useEffect(() => {
    settingSubmitButton();
  }, []);

  return (
    <div className={cl.form_choose}>
      <button
        type="button"
        onClick={() => {
          setIsLogining(true);
          settingSubmitButton();
        }}
        className={`${isLogining ? cl.active_choose : ""} rounded-bl-[12px]`}
      >
        Log in
      </button>
      <button
        onClick={() => {
          setIsLogining(false);
          settingSubmitButton();
        }}
        type="button"
        className={`${isLogining ? "" : cl.active_choose} rounded-br-[12px]`}
      >
        Create account
      </button>
    </div>
  );
};
export default FormChoose;
