import React from "react";
import cl from "./Login.module.scss";
import type {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
// icons
import logInLogo from "image/login_p/login/log_in_logo.svg";
import signUpLogo from "image/login_p/login/sign_up_logo.svg";
import eye from "image/login_p/login/eye.svg";
import closedEye from "image/login_p/login/closed_eye.svg";
// custom components
import Field from "../Field";
import License from "./License";
import FormChoose from "./FormChoose";
import withLoader from "utils/hoc/withLoader";

interface Inputs {
  email: string;
  password: string;
  license?: boolean;
}

interface Props {
  isLogining: boolean;
  setIsLogining: React.Dispatch<React.SetStateAction<boolean>>;
  shownPassword: boolean;
  setShownPassword: React.Dispatch<React.SetStateAction<boolean>>;
  errors: FieldErrors;
  register: UseFormRegister<Inputs>;
  handleSubmit: UseFormHandleSubmit<Inputs>;
  onSubmit: SubmitHandler<Inputs>;
  googleLogin: () => void;
}

const Login: React.FC<Props> = ({
  isLogining,
  setIsLogining,
  shownPassword,
  setShownPassword,
  errors,
  handleSubmit,
  onSubmit,
  register,
  googleLogin,
}) => {
  return (
    <div className={cl.login}>
      <div className={cl.form_title}>
        <img
          src={isLogining ? logInLogo : signUpLogo}
          alt={isLogining ? "Log in logo" : "Sign up logo"}
        />
        <div>
          <p className="mb-[20px] text-[32px]">
            {isLogining ? "Log in" : "Create account"}
          </p>
          <p className="text-[14px] text-[#6E6E6E]">
            {Object.keys(errors).length === 0
              ? "The password must contain at least 4 letters, 3 numbers and 1 special character"
              : errors[Object.keys(errors)[0]]?.message}
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          className="bg-login_mailbox"
          type="email"
          register={register}
          placeholder="Email"
          formValue="email"
          errors={errors}
        />
        <Field
          className="bg-login_shield"
          type={shownPassword ? "text" : "password"}
          register={register}
          placeholder="Password"
          formValue="password"
          errors={errors}
        />
        <img
          src={shownPassword ? closedEye : eye}
          onClick={() => setShownPassword(!shownPassword)}
          alt={shownPassword ? "Hide password" : "Show password"}
          className="cursor-pointer"
        />
        {!isLogining && <License register={register} />}

        <button
          className="mt-10 bg-[#181818] sm:text-[20px] border-b-2 border-b-[#101010] rounded-t-[12px] w-full h-[50px]"
          onClick={googleLogin}
          type="button"
        >
          Use <span className="font-bold sm:text-[20px]">Google</span> account
        </button>

        <FormChoose isLogining={isLogining} setIsLogining={setIsLogining} />
      </form>
    </div>
  );
};

export default withLoader(Login);
