import React, { useRef, useEffect, useState, type RefObject } from "react";
import TwoFA from "./TwoFA";
import ProfilePreview from "./ProfilePreview/ProfilePreview";
import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import type { IFormInputs } from "./SettingsContainer";
import withErrorShow from "utils/hoc/withErrorShow";
import Modal from "utils/components/Modal";
import ConfirmEmailContainer from "./ConfirmEmail";
import cl from "./Settings.module.scss";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";
import Loader from "../Notifications/NotificationsWindow/Loader";

interface SettingsProps {
  settingsRef: RefObject<HTMLDivElement>;
  onBack: () => void;
  watch: UseFormWatch<IFormInputs>;
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  username: string;
  register: UseFormRegister<IFormInputs>;
  tag: string;
  email: string;
  language: string;
  onSubmit: SubmitHandler<IFormInputs>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
  confirmingEmail: boolean;
  setConfirmingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  newEmail: string;
  isLoading: boolean;
}

const Settings: React.FC<SettingsProps> = ({
  settingsRef,
  onBack,
  watch,
  handleSubmit,
  username,
  register,
  tag,
  email,
  language,
  onSubmit,
  isEditing,
  setIsEditing,
  logout,
  confirmingEmail,
  setConfirmingEmail,
  newEmail,
  isLoading,
}) => {
  const resizeRef = useRef<HTMLDivElement | null>(null);
  const [windowSize, setWindowSize] = useState(() => {
    const savedSize = localStorage.getItem("panelSize");
    if (savedSize) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      return { width, height };
    }
    return { width: 432, height: 750 };
  });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && settingsRef.current) {
        const rect = settingsRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem(
          "panelSize",
          JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight }))
        );
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = "";
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = "none";

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = "";
    };
  }, [settingsRef]);

  return (
    <div
      ref={settingsRef}
      className={cl.container}
      style={{
        width: `${windowSize.width}px`,
        height: `${windowSize.height}px`,
        minWidth: "432px",
        minHeight: "750px",
        maxWidth: "510px",
        maxHeight: "870px",
        overflow: "hidden",
      }}
    >
      {isLoading ? (
        <Loader beforeBg="16,16,16" />
      ) : (
        <>
          <div className={"flex items-center justify-center mb-4 relative"}>
            <button
              onClick={onBack}
              type="button"
              className={
                "absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
              }
            >
              ←
            </button>
            <h2 className={"text-[20px] text-center flex-grow"}>Settings</h2>
          </div>
          <ProfilePreview
            watch={watch}
            handleSubmit={handleSubmit}
            username={username}
            register={register}
            tag={tag}
            email={email}
            language={language}
            onSubmit={onSubmit}
            isEditing={isEditing}
          />
          <TwoFA />
          {isEditing ? (
            <div className="mt-4 flex justify-between">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className={cl.cancelButton}
              >
                Cancel
              </button>
              <button
                type="submit"
                form="Account-form"
                className={cl.saveButton}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setIsEditing(true)}
                className={cl.editButton}
              >
                Edit
              </button>
              <p className={cl.logoutText} onClick={logout}>
                Logout
              </p>
            </>
          )}

          <div
            ref={resizeRef}
            className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
            style={{
              borderTopLeftRadius: "25px",
            }}
          >
            .
          </div>

          {confirmingEmail && (
            <Modal setActive={setConfirmingEmail}>
              <ConfirmEmailContainer
                oldEmail={email}
                setConfirmingEmail={setConfirmingEmail}
                newEmail={newEmail}
              />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default withErrorShow(Settings);
