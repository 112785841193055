import { z } from "zod";

export const getValSchema = (pas: string) => {
  return z.object({
    password: z
      .literal(pas) //a small step towards xss protection
      .refine((value) => !value.includes("<") && !value.includes(">"), {
        message: "The password contains prohibited characters",
      }),
  });
};
