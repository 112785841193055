import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Navigate, useNavigate } from "react-router-dom";
import conf_logo from "image/login_p/conf_passwd/conf_logo.svg";
import eye from "image/login_p/login/eye.svg";
import closedEye from "image/login_p/login/closed_eye.svg";
import Field from "../Field";
import { getValSchema } from "utils/validators/Login/ConfPasswd";
import cl from "./ConfPasswd.module.scss";
import useStore from "store";

const ConfPasswd = () => {
  const password = useStore((state) => state.password);
  // Если юзер решит перейти на url /confirm-password, предволительно не посетив /login
  if (password === "") {
    return <Navigate to="/login" />;
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(getValSchema(password)),
  });
  const [shownPassword, setShownPassword] = useState(false);
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate("/login/choose-nickname");
  };

  return (
    <>
      <div className={cl.title}>
        <img src={conf_logo} alt="is loaging" />
        <div className="max-w-[234px]">
          <p className="mb-[20px]">Again write password</p>
          <p className="text-[14px] text-[#6E6E6E]">
            It made because you will forget your password for this account
          </p>
        </div>
      </div>
      <form className="px-4 relative" onSubmit={handleSubmit(onSubmit)}>
        <Field
          className="bg-login_shield"
          type={shownPassword ? "text" : "password"}
          register={register}
          placeholder="Password"
          formValue="password"
          errors={errors}
        />
        <img
          src={shownPassword ? closedEye : eye}
          className="absolute top-0 right-[20%] sm:right-[15%] top-9 pointer-events-auto"
          onClick={() => setShownPassword(!shownPassword)}
          alt="is loaging"
        />
        <div className={cl.buttons}>
          <button
            className="rounded-l-[12px] bg-[#181818]"
            type="button"
            onClick={() => navigate("/login")}
          >
            Back
          </button>
          <button
            className="rounded-r-[12px] bg-[#FFA800] font-bold text-[black]"
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default ConfPasswd;
