import { useEffect, useState, type RefObject } from "react";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DowtAPI } from "services/API/Dowt";
import { type SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import showErrorMessage from "utils/constants/showErrorMessage";
import { queryClient } from "main";
import { valSchema } from "utils/validators/Dowt/Dowt";
import DowtWindow from "./DowtWindow";

export interface IFormInputs {
  "advice type": "1" | "2";
  period: "30_days" | "month_to_date";
}

const DowtWindowContainer = ({
                               dowtRef,
                               onBack,
                             }: {
  dowtRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}) => {
  const { data, isPending } = useQuery({
    queryKey: ["get number of remaining advices"],
    queryFn: DowtAPI.getAdviceNumber,
  });
  const [showLoaderForAdvice, setShowLoaderForAdvice] =
    useState<boolean>(false);
  const {
    mutate: getFirstTypeOfAdvice,
    data: firstTypeOfAdvice,
    isPending: firstAdvicePending,
  } = useMutation({
    mutationKey: ["get dowt's advice of first type"],
    mutationFn: DowtAPI.getFirstTypeOfAdvice,
    onError: (e) => {
      showErrorMessage(e);
      setShowLoaderForAdvice(false);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["get number of remaining advices"],
      });
      localStorage.setItem("prevDowtAdvice", data.data.advice);
    },
  });
  const {
    mutate: getSecondTypeOfAdvice,
    data: secondTypeOfAdvice,
    isPending: secondAdvicePending,
  } = useMutation({
    mutationKey: ["get dowt's advice of second type"],
    mutationFn: DowtAPI.getSecondTypeOfAdvice,
    onError: (e) => {
      showErrorMessage(e);
      setShowLoaderForAdvice(false);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["get number of remaining advices"],
      });
      localStorage.setItem("prevDowtAdvice", data.data.advice);
    },
  });

  const remaininigAdvices: number = 25 - data?.data.advices_left;
  const [displayingTooltip, setDisplayingTooltip] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (data["advice type"] === "1") {
      getFirstTypeOfAdvice(data.period);
    } else {
      getSecondTypeOfAdvice(data.period);
    }
  };
  const advice =
    firstTypeOfAdvice?.data.advice || secondTypeOfAdvice?.data.advice;

  useEffect(() => {
    if (firstAdvicePending || secondAdvicePending) {
      setShowLoaderForAdvice(true);
    }
  }, [firstAdvicePending, secondAdvicePending]);

  const [showPrevAdvice, setShowPrevAdvice] = useState<boolean>(false);

  if (isPending) {
    return <Loader beforeBg="18,18,18" />;
  }

  return (
    <DowtWindow
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      remaininigAdvices={remaininigAdvices}
      register={register}
      displayingTooltip={displayingTooltip}
      showLoaderForAdvice={showLoaderForAdvice}
      dowtRef={dowtRef}
      setDisplayingTooltip={setDisplayingTooltip}
      advice={advice}
      showPrevAdvice={showPrevAdvice}
      setShowPrevAdvice={setShowPrevAdvice}
      onBack={onBack}
    />
  );
};

export default DowtWindowContainer;
