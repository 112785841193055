import type { AxiosError } from "axios";

export default (error: AxiosError) => {
  const res = error.response?.data;
  let alertMessage = "";
  if (typeof res === "object") {
    alertMessage = res[Object.keys(res)[0]];
  } else {
    alertMessage = res;
  }
  alert(alertMessage);
};
