import React, { useState } from "react";
import currencyToSymbolMap from "currency-symbol-map";
import type { ITotalBalance } from "utils/types/response/AccountRes";

const ShowTotalBalance = React.memo(
  ({ TotalBalance }: { TotalBalance: ITotalBalance }) => {
    const [showFullBalance, setShowFullBalance] = useState<boolean>(false);
    const currencySymbol = currencyToSymbolMap(TotalBalance.currency);
    const { balance, formatted_balance } = TotalBalance;

    return (
      <div
        onMouseLeave={() => setShowFullBalance(false)}
        onMouseEnter={() => setShowFullBalance(true)}
        className="text-white text-[18px] relative bg-gradient-to-t flex flex-col items-center from-[#ff5c02] to-[#ff7528] px-1 text-center rounded-[7px]"
      >
        {showFullBalance && (
          <p className="!absolute border border-[#252525] -top-6 bg-[#19191A] px-2 rounded-[7px]">
            {currencySymbol + Number.parseFloat(balance)}
          </p>
        )}
        <p className="text-[15px]">Total balance</p>
        <p>{currencySymbol + formatted_balance}</p>
      </div>
    );
  }
);

export default ShowTotalBalance;
