import utilCl from "utils/Classes.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import withLoader from "utils/hoc/withLoader";
import cl from "./Form.module.scss";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./FormContainer";
import type { Dispatch, FC, SetStateAction } from "react";

interface Props extends IFormProps<IFormInputs> {
  setCreatingGoal: Dispatch<SetStateAction<boolean>>;
  submitButtonText: string;
}

const Form: FC<Props> = ({
  handleSubmit,
  onSubmit,
  register,
  setCreatingGoal,
  submitButtonText = "Create",
}) => (
  <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
    <input
      autoComplete="off"
      placeholder="title of this account"
      {...register("title")}
    />
    <input autoComplete="off" placeholder="amount" {...register("goal")} />
    <button
      type="button"
      onClick={() => setCreatingGoal(false)}
      className={`${utilCl.black_button} rounded-l-xl py-3`}
    >
      Cancel
    </button>
    <button className={`${utilCl.yellow_button} rounded-r-xl`} type="submit">
      {submitButtonText}
    </button>
  </form>
);

export default withLoader(withErrorShow(Form));
