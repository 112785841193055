import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import clUtils from "utils/Classes.module.scss";

const Stripe = () => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://spendsplif.com",
      },
    });

    if (result.error) {
      alert(`something wrong ${result.error.message}`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-[#2d2d2d] text-white flex flex-col font-vela text-2xl p-4 rounded-xl"
    >
      <p>Please enter your payment details</p>
      <PaymentElement />
      <button
        type="submit"
        className={`${clUtils.yellow_button} w-full rounded-md mt-4 !font-normal py-1`}
      >
        Submit
      </button>
    </form>
  );
};

export default Stripe;
