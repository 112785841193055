import { type SubmitHandler, useForm } from "react-hook-form";
import ConverterForm from "./ConverterForm";
import { useMutation } from "@tanstack/react-query";
import type { ConverterReq } from "utils/types/request/ConverterReq";
import { ConvertAPI } from "services/API/Converter";
import { currencies } from "utils/constants/currencies";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Converter/Converter";
import showErrorMessage from "utils/constants/showErrorMessage";

export interface IFormInputs {
  from_currency: keyof typeof currencies;
  amount: string;
  to_currency: keyof typeof currencies;
}

const ConverterFormContainer = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: zodResolver(valSchema) });

  const { mutate, isPending, data, isSuccess } = useMutation({
    mutationKey: ["convert"],
    mutationFn: (data: ConverterReq) => ConvertAPI(data),
    onError: showErrorMessage,
  });
  const onSubmit: SubmitHandler<IFormInputs> = ({
    from_currency,
    amount,
    to_currency,
  }) => {
    mutate({
      from_currency: currencies[from_currency],
      amount: Number.parseFloat(amount),
      to_currency: currencies[to_currency],
    });
  };

  return (
    <ConverterForm
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      setValue={setValue}
      errors={errors}
      isLoading={isPending}
      result={isSuccess ? data?.data["Converted successfully:"] : undefined}
    />
  );
};

export default ConverterFormContainer;
