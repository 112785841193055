import React, { useEffect, useState, useCallback, useRef } from "react";
import { HistoryAPI } from "services/API/History";
import type { HistoryOne } from "utils/types/response/HistoryRes";
import HistoryEditWindow from "./EditHistory/HistoryEditWindow.tsx";
import styles from "./HistoryWindow.module.scss";
import LossHeaderIcon from "image/History/Loss.svg";
import IncomeHeaderIcon from "image/History/Income.svg";
import useStore from "../../../../store";
import type { AxiosResponse } from "axios";
import { sanitizeObject } from "utils/functions/sanitizeObject.ts";
import DOMPurify from "dompurify";

interface HistoryWindowProps {
  historyRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const HistoryWindow: React.FC<HistoryWindowProps> = ({ historyRef, onClose }) => {
  const [historyData, setHistoryData] = useState<HistoryOne[]>([]);
  const [expandedComments, setExpandedComments] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [editingEntry, setEditingEntry] = useState<HistoryOne | null>(null);
  const { setCategoryChanged } = useStore((state) => state);
  const resizeRef = useRef<HTMLDivElement | null>(null);

  const fetchHistory = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await HistoryAPI.getNotifications(historyData.length + 20);
      setHistoryData(prevData => [...prevData, ...response.data.slice(prevData.length)]);
    } catch (error) {
      console.error("Failed to fetch history", error);
    } finally {
      setLoading(false);
    }
  }, [loading, historyData.length]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  useEffect(() => {
    const savedSize = localStorage.getItem('panelSize');
    if (savedSize && historyRef.current) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      historyRef.current.style.width = `${width}px`;
      historyRef.current.style.height = `${height}px`;
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && historyRef.current) {
        const rect = historyRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        historyRef.current.style.width = `${newWidth}px`;
        historyRef.current.style.height = `${newHeight}px`;

        // Сохраняем новые размеры в localStorage
        localStorage.setItem('panelSize', JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight })));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = '';
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = 'none';

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = '';
    };
  }, [historyRef]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    setShowLoadMore(scrollHeight - scrollTop <= clientHeight + 1);
  }, []);

  const formatEntryId = (type: string, id: number) => {
    return `${type}-${id}`;
  };

  const handleEdit = (entry: HistoryOne) => {
    setEditingEntry(entry);
  };

  const handleDelete = async (formattedId: string) => {
    const [type, idString] = formattedId.split('-');
    const id = Number.parseInt(idString);
    try {
      let deleteFunction: (id: number) => Promise<AxiosResponse<void>>;
      switch (type) {
        case 'income':
          deleteFunction = HistoryAPI.deleteIncome;
          break;
        case 'expense':
          deleteFunction = HistoryAPI.deleteExpense;
          break
        case 'loss':
          deleteFunction = HistoryAPI.deleteExpense;
          break;
        default:
          throw new Error('Invalid type for deleting entry');
      }

      await deleteFunction(id);
      setCategoryChanged(true)

      setHistoryData(prevData => prevData.filter(entry => entry.id !== id));
    } catch (error) {
      console.error(`Failed to delete entry with id: ${formattedId}`, error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      day: 'numeric',
      month: 'long'
    });
  };

  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  const getStyleHeader = (type: string) => {
    switch (type) {
      case 'income':
        return styles.incomeHeader;
      case 'loss':
        return styles.lossHeader;
      case 'expense':
        return styles.expenseHeader;
    }
  };

  const renderIcon = (type: string, categoryTitle: string | undefined) => {
    if (type === 'income') {
      return <img className={`${styles.icon} ${styles.incomeIcon}`} src={IncomeHeaderIcon} alt={type} />;
    }if (type === 'loss') {
      return <img className={`${styles.icon} ${styles.lossIcon}`} src={LossHeaderIcon} alt={type} />
    }if (type === 'expense') {
      return (
        <img
          className={`${styles.icon} ${styles.expenseIcon}`}
          src={`https://spendsplif.com/icons/history/${categoryTitle}.svg`}
          alt={categoryTitle}
        />
      );
    }
  };

  const renderHeader = (type: string, categoryTitle: string | undefined, categoryIcon: string | undefined) => {
    const headerClass = getStyleHeader(type);
    return (
      <div className={headerClass}>
        <div className={styles.headerText}>
          {categoryTitle || type.charAt(0).toUpperCase() + type.slice(1)}
        </div>
        <div className={styles.headerIcon}>
          {renderIcon(type, categoryIcon)}
        </div>
      </div>
    );
  };

  const toggleExpand = (formattedId: string) => {
    setExpandedComments(prevState => {
      const newState = new Set(prevState);
      if (newState.has(formattedId)) {
        newState.delete(formattedId);
      } else {
        newState.add(formattedId);
      }
      return newState;
    });
  };

  const handleSave = (updatedEntry: HistoryOne) => {
    setHistoryData((prevData) =>
      prevData.map((entry) => (entry.id === updatedEntry.id ? updatedEntry : entry))
    );
    setEditingEntry(null);
  };

  return (
    <div
      ref={historyRef}
      className={`${styles.container} min-w-[432px] min-h-[750px] w-[432px] h-[750px]`}
      style={{ maxHeight: '870px', maxWidth: '510px', overflow: 'hidden' }}
    >
      <div className={styles.titleAndBack}>
        <button type="button" onClick={onClose} className={styles.backButton}>
          ←
        </button>
        <h2 className={styles.title}>History</h2>
      </div>
      <div className={styles.list} onScroll={handleScroll}>
        {historyData.map((entry) => {
          const formattedDate = formatDate(entry.created_date);
          const formattedId = formatEntryId(entry.type, entry.id);
          return (
            <React.Fragment key={formattedId}>
              <div className={styles.dateHeader}>
                <span className={styles.date}>{formattedDate}</span>
                <span className={styles.time}>{formatTime(entry.created_time)}</span>
              </div>
              <div className={styles.listItem}>
                {renderHeader(entry.type, entry.category_title, entry.category_icon)}
                <div className={styles.detailsContainer}>
                  <div className={styles.detailItem}>
                    <div className={styles.amount}>
                      {entry.amount} {entry.currency}
                    </div>
                    <div className={styles.commentConverter}>
                      <div
                        className={styles.commentView}
                        onClick={() => toggleExpand(formattedId)}
                      >
                        <p
                          className={`${styles.commentText} ${expandedComments.has(formattedId) ? styles.expandedComment : ""}`}
                        >
                          {entry.comment || "No comment"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailItem}>
                    <div className={styles.accountInfo}>
                      {entry.account} - {entry.account_balance} {entry.currency}
                    </div>
                  </div>
                </div>
                <div className={styles.actions}>
                  <button type="button" onClick={() => handleEdit(entry)} className={styles.editButton}>
                    <span className={styles.buttonText}>Edit</span>
                  </button>
                  <button type="button" onClick={() => handleDelete(formattedId)} className={styles.deleteButton}>
                    <span className={styles.buttonText}>Delete</span>
                  </button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {showLoadMore && (
        <div className={styles.loadMoreContainer}>
          <button
            type="button"
            onClick={fetchHistory}
            className={styles.loadMoreButton}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}
      {editingEntry && (
        <HistoryEditWindow
          entry={editingEntry}
          setActive={setEditingEntry}
          onSave={handleSave}
        />
      )}
      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: '25px',
        }}
      >.</div>
    </div>
  );
};

export default HistoryWindow;