import type { AxiosResponse } from "axios";
import axios from "axios";
import type {
  RegisterResponse,
  LoginResponse,
} from "utils/types/response/AuthResponse";
import type {
  RegisterRequest,
  LoginRequest,
} from "utils/types/request/AuthRequest";
import type { CategoryInputs } from "components/HomePage/Overlay/CreateCategory/PC/CreateCategory.tsx";
import type { OneCategory } from "utils/types/response/CategoriesRes";
import { instance } from "./API/instances.ts"
import getSpaceId from "../utils/constants/getSpaceId.ts";

const serverURL = "https://api.spendsplif.com/api/v1";

//we need the authInstance because interceptors on the instance interfere correct work login
const authInstance = axios.create({
  baseURL: serverURL,
  withCredentials: true,
});

const instance = axios.create({
  baseURL: serverURL,
  withCredentials: true,
});

export const AuthAPI = {
  async register(
    data: RegisterRequest
  ): Promise<AxiosResponse<RegisterResponse>> {
    return await authInstance.post<RegisterResponse>("/register/", {
      ...data,
    });
  },

  async login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return await authInstance.post<LoginResponse>("/token/", { ...data });
  },

  async verifyEmail(code: string, currency: string): Promise<AxiosResponse> {
    return await authInstance.post("/verify_email/", {
      verify_code: code,
      currency: currency,
    });
  },
  //Ето нужно будет типизировать когда refresh токен начнется прикреплятса как кука
  async checkAuth() {
    return await authInstance.get("/token/refresh/");
  },
};

// Automatically attaches an access token to each request
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

//Checks each response. If the error is 401, the access token has probably expired.
//A token refresh request occurs and repeats the previous request
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      try {
        const response = await AuthAPI.checkAuth();
        localStorage.setItem("token", response.data.access);
        return instance.request(originalRequest);
      } catch {
        console.log("You`re not authorized");
      }
    }
  }
);

export const SpaceAPI = {
  async getMySpaces() {
    try {
      const response = await instance.get("/my_spaces/");
      return response.data;
    } catch (error) {
      console.error("Error fetching spaces:", error);
      throw error;
    }
  },

  async createSpace(data: { title: string; currency: string }) {
    try {
      const response = await instance.post("/create_space/", data);
      return response.data;
    } catch (error) {
      console.error("Error creating space:", error);
      throw error;
    }
  },

  async getSpace(spaceId: number) {
    try {
      const response = await instance.get(`/my_spaces/${spaceId}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching space:", error);
      throw error;
    }
  },

  async updateSpace(
    spaceId: number,
    data: { title: string; currency: string }
  ) {
    try {
      const response = await instance.put(`/my_spaces/${spaceId}/`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating space:", error);
      throw error;
    }
  },

  async getSpaceMembers(spaceId: number) {
    try {
      const response = await instance.get(`/my_spaces/${spaceId}/members/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching space members:", error);
      throw error;
    }
  },

  async addMemberToSpace(spaceId: number, userEmail: string) {
    try {
      const response = await instance.put(`/my_spaces/${spaceId}/add_member/`, {
        user_email: userEmail,
      });
      return response.data;
    } catch (error) {
      console.error("Error adding member:", error);
      throw error;
    }
  },

  async getMemberPermissions(spaceId: number, memberId: number) {
    try {
      const response = await instance.get(
        `/my_spaces/${spaceId}/edit_member/${memberId}/`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching member permissions:", error);
      throw error;
    }
  },

  async updateMemberPermissions(
    spaceId: number,
    memberId: number,
    permissions: any
  ) {
    try {
      const response = await instance.put(
        `/my_spaces/${spaceId}/edit_member/${memberId}/`,
        permissions
      );
      return response.data;
    } catch (error) {
      console.error("Error updating member permissions:", error);
      throw error;
    }
  },

  async deleteSpace(spaceId: number) {
    try {
      const response = await instance.delete(`/delete_space/${spaceId}/`);
      return response.data;
    } catch (error) {
      console.error("Error deleting space:", error);
      throw error;
    }
  },

  leaveFromSpace(spaceId: number) {
    return instance.post(`/my_spaces/${spaceId}/leave/`);
  },
};

export const CategoryAPI = {
  async create(data: CategoryInputs, spaceId: number) {
    return await instance.post(`/my_spaces/${spaceId}/create_category/`, {
      ...data,
    });
  },
  async getCategories(spaceId: number): Promise<AxiosResponse<OneCategory[]>> {
    try {
      return instance.get(`/my_spaces/${spaceId}/my_categories/`);
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  },
  async getOne(spaceId: number, categoryId: number) {
    try {
      const response = await instance.get(
        `/my_spaces/${spaceId}/my_categories/${categoryId}/`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error;
    }
  },
  async update(spaceId: number, categoryId: number, data: CategoryInputs) {
    try {
      const response = await instance.put(
        `/my_spaces/${spaceId}/my_categories/${categoryId}/`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error updating category:", error);
      throw error;
    }
  },
  async delete(spaceId: number, categoryId: number) {
    try {
      const response = await instance.delete(
        `/my_spaces/${spaceId}/delete_category/${categoryId}/`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  },
};

export const SpendAPI = {
  async spendAccount(
    spaceId: number,
    data: {
      account_pk: number;
      category_pk?: number;
      amount: number;
      comment?: string;
    }
  ) {
    try {
      const response = await instance.put(`/my_spaces/${spaceId}/spend/`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      console.error("Error spending from account:", error);
      throw error;
    }
  },
};
