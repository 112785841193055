import { z } from "zod";

const codeSchema = z
  .string()
  .length(8, { message: "code must be 8 characters long" })
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "Your code contains prohibited characters",
  });

export const valSchema = z.object({
  verify_code: codeSchema,
  code_from_new_email: codeSchema,
});
