import React, { useState, lazy, Suspense } from "react";
import cl from "./Circle.module.scss";
import useStore from "store/store";
import Plus from "/src/image/expenses/plus.svg";
import PlusWhite from "/src/image/expenses/plus white.svg";
import EditLogo from "/src/image/Account/EditLogo.svg";
import Loader from "utils/components/Loader/Loader";
const EditCategory = lazy(
  () => import("../Overlay/EditCategory/PC/EditCategory.tsx")
);
import OutgoingModal from "../Overlay/SpendCategory/OutgoingModal";
import DOMPurify from "dompurify";
import currencyToSymbolMap from "currency-symbol-map";

type CircleProps = {
  className: string;
  id: string;
  category?: {
    id: string;
    title: string;
    color: string;
    icon: string;
    spent: number;
    limit: number | null;
    limit_formatted: string | null;
  };
  disabled: boolean;
};

const Circle: React.FC<CircleProps> = React.memo(
  ({ className, id, category, disabled }) => {
    const { setShowDialog } = useStore((state) => state);
    const [isHovered, setIsHovered] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isSpendOpen, setIsSpendOpen] = useState(false);

    const handleSpendClick = () => {
      setIsSpendOpen(true);
    };

    const handleEditClick = () => {
      setIsEditOpen(true);
    };

    if (!category) {
      return (
        <div className={`${cl.circle} ${className} opacity-70`}>
          <button
            className="z-10 opacity-70"
            type="button"
            onClick={() => setShowDialog(true)}
            disabled={disabled}
          >
            <img src={Plus} className="w-16" alt="Add category" />
          </button>
        </div>
      );
    }

    const radius = 101;
    const strokeWidth = 10.5;
    const circumference = 2 * Math.PI * radius;
    const progress = category.limit
      ? (category.spent / category.limit) * 100
      : 100;
    const isOverLimit = progress > 100;
    const offset = isOverLimit ? 0 : ((100 - progress) / 100) * circumference;

    const iconPath = category.icon
      ? `https://spendsplif.com/icons/${category.icon}.svg`
      : undefined;
    const isHomeIcon = category.icon === "Home";

    let currency;
    let currencySymbol;
    if (category) {
      currency = JSON.parse(
        DOMPurify.sanitize(localStorage.getItem("space"))
      ).currency;
      currencySymbol = currencyToSymbolMap(currency);
    }
    return (
      <>
        <div
          className={`${cl.circle} ${className}`}
          style={{ "--category-color": category.color, cursor: "default" }} // Добавляем стиль cursor: default
          onMouseEnter={() => (!disabled ? setIsHovered(true) : undefined)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {iconPath && (
            <img
              src={iconPath}
              className={`${cl.icon} ${isHomeIcon ? cl.homeIcon : ""} ${
                isHovered && !disabled ? cl.backgroundIcon : ""
              }`}
              alt={category.title}
            />
          )}
          {isHovered ? (
            <div className={cl.hoverContent}>
              <button
                type="button"
                disabled={disabled}
                className={cl.spendButton}
                onClick={handleSpendClick}
              >
                <img src={PlusWhite} className="w-16" alt="Spend" />
              </button>
              <button
                type="button"
                disabled={disabled}
                className={cl.editButton}
                onClick={handleEditClick}
              >
                <img src={EditLogo} className="w-6" alt="Edit" />
              </button>
            </div>
          ) : (
            <>
              <svg
                className={cl.progressCircle}
                width="220"
                height="220"
                viewBox="-10 -10 220 220"
              >
                <defs>
                  <filter id="glow">
                    <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>
                <circle
                  className={cl.circleBackground}
                  cx="100"
                  cy="100"
                  r={radius}
                  strokeWidth={strokeWidth}
                />
                <circle
                  className={cl.circleProgress}
                  cx="100"
                  cy="100"
                  r={radius}
                  strokeWidth={strokeWidth}
                  strokeDasharray={circumference}
                  strokeDashoffset={offset}
                  filter="url(#glow)"
                  transform="rotate(-90 100 100)"
                />
                {category.limit !== null && (
                  <>
                    <defs>
                      <path id="textPath" d="M65 ,9 A90,80 20 0 1 145,165" />
                      <path
                        id="overLimitTextPath"
                        d="M70 ,8 A90,90 30 0 1 135,12"
                      />
                    </defs>
                    {isOverLimit ? (
                      <text className={cl.percentageText}>
                        <textPath
                          xlinkHref="#overLimitTextPath"
                          startOffset="50%"
                          textAnchor="middle"
                        >
                          <tspan
                            className={cl.overLimitPercentage}
                          >{`${Math.round(progress)}%`}</tspan>
                        </textPath>
                      </text>
                    ) : (
                      <>
                        <text className={cl.percentageText}>
                          <textPath xlinkHref="#textPath" startOffset="-3">
                            <tspan
                              className={cl.remainingPercentage}
                            >{`${Math.round(100 - progress)}%`}</tspan>
                          </textPath>
                        </text>
                        <text className={cl.percentageText}>
                          <textPath xlinkHref="#textPath" startOffset="17%">
                            <tspan
                              className={cl.filledPercentage}
                            >{`${Math.round(progress)}%`}</tspan>
                          </textPath>
                        </text>
                      </>
                    )}
                  </>
                )}
              </svg>
              <div className={cl.content}>
                <div className={cl.title}>{category.title}</div>
                <div className={cl.spent}>
                  {currencySymbol + category.spent}
                </div>
                {category.limit_formatted !== null && (
                  <div className={cl.limit}>
                    out of {currencySymbol + category.limit_formatted}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <Suspense fallback={<Loader />}>
          <EditCategory
            initialData={{
              title: category.title,
              color: category.color,
              icon: category.icon,
              ...(category.limit !== null && { limit: category.limit }),
            }}
            isOpen={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            categoryId={Number.parseInt(category.id)}
          />
        </Suspense>
        {isSpendOpen && (
          <OutgoingModal
            setModal={setIsSpendOpen}
            categoryId={Number.parseInt(category.id)}
          />
        )}
      </>
    );
  }
);

export default Circle;
