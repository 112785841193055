import Form from "../CreateAccount/Form";
import type { Dispatch, SetStateAction } from "react";
import CurrencyMenu from "components/LoginPage/ChooseCurrency/CurrencyMenu";
import Title from "../CreateAccount/Title";
import Setting from "image/CreateCategory/svg/Bold/Settings.svg";
import type {
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  SubmitHandler,
} from "react-hook-form";
import type { IFormInputs } from "../CreateAccount/CreateAccountContainer";
import type { useMutation } from "@tanstack/react-query";
import withLoader from "utils/hoc/withLoader";

const EditAccount: React.FC<{
  setModal: Dispatch<SetStateAction<boolean>>;
  register: UseFormRegister<IFormInputs>;
  onSubmit: SubmitHandler<IFormInputs>;
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  setValue: UseFormSetValue<IFormInputs>;
  errors: { [key: string]: { message: string } };
  DeleteAccountReq: ReturnType<typeof useMutation>;
}> = ({
  setModal,
  errors,
  register,
  handleSubmit,
  setValue,
  onSubmit,
  DeleteAccountReq,
}) => {
  return (
    <div className="grid grid-cols-[minmax(570px, 1fr)_minmax(auto, 80vw)] grid-rows-[180px_400px] font-bold">
      <Title
        image={Setting}
        header={"Edit account"}
        title={
          "Fill in the information \n that you need to change in the account"
        }
      />
      <Form
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        setModal={setModal}
        onSubmit={onSubmit}
        // the text displayed on the submit button
        submitText="Edit account"
        AdditionalButton={
          <button
            type="button"
            onClick={() => {
              if (window.confirm("You are going to delete your account")) {
                DeleteAccountReq.mutate();
              }
            }}
            className="w-14 mt-4 text-[#6E6E6E] justify-self-center text-[20px] col-[1/4]"
          >
            Delete
          </button>
        }
      />
      <CurrencyMenu
        onClick={(e) => {
          setValue("currency", e.target.dataset.text, {
            shouldValidate: true,
          });
        }}
        style={{ gridRow: "1/3" }}
      />
    </div>
  );
};

export default withLoader(EditAccount);
