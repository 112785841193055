import type { AxiosError, AxiosResponse } from "axios";
import { instance } from "./instances";
import type {
  ICreateAccountReq,
  IIncomeAccountReq,
  ISpendAccountReq,
} from "utils/types/request/AccountRequest";
import type { ViewAccountsRes } from "utils/types/response/AccountRes";
import DOMPurify from "dompurify";

const getSpaceId = () => JSON.parse(DOMPurify.sanitize(localStorage.getItem("space"))).id;

export const AccountAPI = {
  CreateAccount(data: ICreateAccountReq): Promise<AxiosResponse> {
    return instance.post(`/my_spaces/${getSpaceId()}/create_account/`, {
      ...data,
    });
  },
  ViewAccounts: () =>
    instance.get<ViewAccountsRes>(`/my_spaces/${getSpaceId()}/space_accounts/`),

  EditAccount(accountId: number, data: ICreateAccountReq) {
    return instance.put(
      `/my_spaces/${getSpaceId()}/space_accounts/${accountId}/`,
      { ...data }
    );
  },
  DeleteAccount(accountId: number) {
    return instance.delete(
      `/my_spaces/${getSpaceId()}/delete_account/${accountId}/`
    );
  },
  IncomeAccount(accountId: number, data: IIncomeAccountReq) {
    return instance.put(
      `/my_spaces/${getSpaceId()}/space_accounts/${accountId}/income/`,
      data
    );
  },
  SpendAccount(data: ISpendAccountReq) {
    return instance.put(`/my_spaces/${getSpaceId()}/spend/`, data);
  },
};
