import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import currencyToSymbolMap from "currency-symbol-map";
import AccountCircle from "./AccountCircle";
import useStore from "store/store";

const AccountCircleContainer = () => {
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const { setAccounts, accounts, setTotalBalance, totalBalance } = useStore();
  const { data, isPending } = useQuery({
    queryKey: ["get accounts"],
    queryFn: () => AccountAPI.ViewAccounts(),
    refetchInterval: (query) =>
      // if it today's month then we use interval refetch
      accounts === query.state.data?.data.Account ? 5000 : false, // Опрашивать каждые 5 секунд
    refetchIntervalInBackground: true,
    // if user use the months feature we dont use query during changing tabs
    refetchOnWindowFocus: (query) =>
      accounts === query.state.data?.data.Account,
  });
  useEffect(() => {
    if (data) {
      setAccounts(data.data.Account);
      setTotalBalance(data.data.TotalBalance);
    }
  }, [data, setAccounts]);
  const [accountNumber, setAccountNumber] = useState<number>(
    Number.parseInt(localStorage.getItem("accountNumber")) || 0
  );
  localStorage.setItem("accountNumber", accountNumber.toString());
  const Account = accounts ? accounts[accountNumber] : undefined;
  const currencySymbol = Account && currencyToSymbolMap(Account?.currency);
  const showTotalBalance =
    totalBalance &&
    accounts?.length !== 0 &&
    accounts?.length === accountNumber;
  const TotalBalance = totalBalance ? totalBalance[0] : undefined;
  const disabedCircle = accounts !== data?.data.Account;

  return (
    <AccountCircle
      setAccountNumber={setAccountNumber}
      Account={Account}
      currencySymbol={currencySymbol}
      setCreatingAccount={setCreatingAccount}
      creatingAccount={creatingAccount}
      isLoading={isPending}
      showTotalBalance={showTotalBalance}
      TotalBalance={TotalBalance}
      accountsLength={accounts?.length}
      disabled={disabedCircle}
    />
  );
};

export default AccountCircleContainer;
