// LoginContainer.jsx
import React, { useEffect, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { getValSchema } from "utils/validators/Login/Login";
import useStore from "store";
import useLogin from "utils/hooks/useLogin";
import Login from "./Login";
import Loader from "utils/components/Loader/Loader";

interface Inputs {
  email: string;
  password: string;
  license?: boolean;
}

const LoginContainer = () => {
  const { setEmail, email, setPassword, password, currency } = useStore(
    (store) => store,
  );
  const [isLogining, setIsLogining] = useState<boolean>(true);
  const { mutateAsync, isPending } = useLogin(!isLogining);
  const [shownPassword, setShownPassword] = useState<boolean>(false);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage("Here");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(getValSchema(isLogining)),
  });

  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useState<boolean>(false);

  if (isLoader) {
    return <Loader />;
  }
  useEffect(() => {
    if (email && password && currency) {
      mutateAsync();
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setEmail(data.email);
    setPassword(data.password);
    mutateAsync().catch(() => {
      if (!isLogining) {
        navigate("confirm-password");
      }
    });
  };

  const handleGoogleLogin = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("Google Auth");
      return null
    }
    window.location.href = "https://api.spendsplif.com/api/v1/auth/google/login/";
    localStorage.setItem("token", " ");
  };

  return (
    <Login
      isLogining={isLogining}
      setIsLogining={setIsLogining}
      shownPassword={shownPassword}
      setShownPassword={setShownPassword}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isPending}
      googleLogin={handleGoogleLogin}
    />
  );
};

export default LoginContainer;
