import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { HistoryAPI, EditHistoryEntryData } from "services/API/History";
import type { HistoryOne } from "utils/types/response/HistoryRes";
import Modal from "utils/components/Modal/Modal.tsx";
import styles from "./HistoryEditWindow.module.scss";
import useStore from "store/store.ts";
import EditHistoryBannerIcon from "image/History/EditHistoryIcon.svg"

interface HistoryEditWindowProps {
  entry: HistoryOne;
  setActive: Dispatch<SetStateAction<boolean>>;
  onSave: (updatedEntry: HistoryOne) => void;
}

const HistoryEditWindow: React.FC<HistoryEditWindowProps> = ({
                                                               entry,
                                                               setActive,
                                                               onSave,
                                                             }) => {
  const [accountId, setAccountId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [amount, setAmount] = useState(entry.amount.toString());
  const [comment, setComment] = useState(entry.comment);
  const { setCategoryChanged } = useStore((state) => state);

  const { accounts, categories } = useStore();

  useEffect(() => {
    // Устанавливаем accountId по имени, если он не установлен
    if (accountId === null) {
      const matchedAccount = accounts.find(
        (account) => account.title === entry.account
      );
      if (matchedAccount) {
        setAccountId(matchedAccount.id);
      }
    }

    // Устанавливаем categoryId по имени, если он не установлен и тип "expense"
    if (entry.type === "expense" && categoryId === null && entry.category_title) {
      const matchedCategory = categories.find(
        (category) => category.title === entry.category_title
      );
      if (matchedCategory) {
        setCategoryId(matchedCategory.id);
      }
    }
  }, [accountId, categoryId, accounts, categories, entry]);

  const handleSave = async () => {
    const updatedData: EditHistoryEntryData = {
      account: accountId!,
      amount: parseFloat(amount),
      comment,
    };

    if (entry.type === "expense" && categoryId !== null) {
      updatedData.category = categoryId;
    }

    try {
      const response = await HistoryAPI.editHistoryEntry(entry.id, entry.type, updatedData);
      onSave(response.data);
      setCategoryChanged(true)
      setActive(false);
    } catch (error) {
      console.error("Failed to update history entry", error);
    }
  };

  return (
    <Modal setActive={setActive}>
      <div className={styles.container}>
        <div className={styles.banner}>
          <img src={EditHistoryBannerIcon} alt="icon" className={styles.bannerIcon} />
          <div className={styles.titleAndSubtitleContainer}>
            <span className={styles.titleSpan}>Edit History</span>
            <span className={styles.subtitleSpan}>Change the fields as you see fit and click the create button</span>
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldTitle}>Account</span>
          <select
            value={accountId || ""}
            onChange={(e) => setAccountId(Number(e.target.value))}
            className={styles.select}
          >
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.title} | {account.balance} {account.currency}
              </option>
            ))}
          </select>
        </div>
        {entry.type === "expense" && (
          <div className={styles.field}>
            <span className={styles.fieldTitle}>Category</span>
            <select
              value={categoryId || ""}
              onChange={(e) => setCategoryId(Number(e.target.value))}
              className={styles.select}
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.field}>
          <span className={styles.fieldTitle}>Amount</span>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.field}>
          <span className={styles.fieldTitle}>Comment</span>
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.actions}>
          <button onClick={() => setActive(false)} className={styles.cancelButton}>
            Cancel
          </button>
          <button onClick={handleSave} className={styles.saveButton}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HistoryEditWindow;
