import React, { useState, useRef, useEffect } from "react";
import cl from "./DowtWindow.module.scss";
import ProgressBar from "./ProgressBar";
import Tooltip from "./Tooltip";
import Loader from "../Notifications/NotificationsWindow/Loader";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./DowtWindowContainer";
import Advice from "./Advice";
import historyIcon from "image/Clock Circle.svg";
import closeIcon from "image/Close Circle.svg";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import utilCl from "utils/Classes.module.scss";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

interface Props extends IFormProps<IFormInputs> {
  dowtRef: React.RefObject<HTMLDivElement>;
  showLoaderForAdvice: boolean;
  displayingTooltip: boolean;
  setDisplayingTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  remaininigAdvices: number;
  advice: string | undefined;
  showPrevAdvice: boolean;
  setShowPrevAdvice: React.Dispatch<React.SetStateAction<boolean>>;
  onBack: () => void;
}

const DowtWindow: React.FC<Props> = React.memo(
  ({
    dowtRef,
    showLoaderForAdvice,
    register,
    handleSubmit,
    onSubmit,
    displayingTooltip,
    setDisplayingTooltip,
    remaininigAdvices,
    advice,
    showPrevAdvice,
    setShowPrevAdvice,
    onBack,
  }: Props) => {
    const prevAdvice = localStorage.getItem("prevDowtAdvice");
    useSetCoolScroll(dowtRef);

    const displayedAdvice = showPrevAdvice ? prevAdvice : advice;

    const [windowSize, setWindowSize] = useState(() => {
      const savedSize = localStorage.getItem('panelSize');
      if (savedSize) {
        const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
        return { width, height };
      }
      return { width: 432, height: 750 };
    });

    const resizeRef = useRef<HTMLDivElement>(null);
    const isResizing = useRef(false);

    const aspectRatio = 432 / 750;

    useEffect(() => {
      const handleResize = (e: MouseEvent) => {
        if (isResizing.current && dowtRef.current) {
          const rect = dowtRef.current.getBoundingClientRect();
          let newWidth = Math.max(432, Math.min(e.clientX - rect.left, 510));
          let newHeight = newWidth / aspectRatio;

          if (newHeight > 870) {
            newHeight = 870;
            newWidth = newHeight * aspectRatio;
          }

          setWindowSize({ width: newWidth, height: newHeight });
          localStorage.setItem(
            "panelSize",
            JSON.stringify(
              sanitizeObject({ width: newWidth, height: newHeight })
            )
          );
        }
      };

      const stopResizing = () => {
        isResizing.current = false;
      };

      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", stopResizing);

      return () => {
        window.removeEventListener("mousemove", handleResize);
        window.removeEventListener("mouseup", stopResizing);
      };
    }, [dowtRef]);

    const startResizing = () => {
      isResizing.current = true;
    };

    const loaderWidth = windowSize.width;
    const loaderHeight = windowSize.height * 0.84;

    return (
      <div
        ref={dowtRef}
        className={cl.windowWrapper}
        style={{ width: `${windowSize.width}px`, height: `${windowSize.height}px` }}
      >
        <div className="flex items-center justify-between mb-4 relative w-full p-6">
          <button
            onClick={onBack}
            type="button"
            className="text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
          >
            ←
          </button>
          <h2 className="text-[20px]">Ask Dowt for advice</h2>
          {prevAdvice && (
            <button
              className="text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
              type="button"
              title={showPrevAdvice ? "Close previous advice" : "Show previous advice"}
              onClick={() => setShowPrevAdvice(!showPrevAdvice)}
            >
              <img
                className="w-[30px] h-[30px]"
                src={showPrevAdvice ? closeIcon : historyIcon}
                alt={showPrevAdvice ? "Close previous advice" : "Show previous advice"}
              />
            </button>
          )}
        </div>
        <div className={cl.window}>
          {showLoaderForAdvice && (
            <div
              className={cl.loader}
              style={{
                width: `${loaderWidth}px`,
                height: `${loaderHeight}px`,
              }}
            >
              <Loader beforeBg="18,18,18" />
            </div>
          )}
          <>
            <p>Period</p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <select {...register("period")}>
                <option value="" disabled>
                  Select period
                </option>
                <option value="30_days">30 days</option>
                <option value="month_to_date">This month</option>
              </select>
              <div className="flex relative items-center">
                {displayingTooltip && (
                  <Tooltip onMouseLeave={() => setDisplayingTooltip(false)} />
                )}
                <p>Advice type</p>
                <p
                  onMouseEnter={() => setDisplayingTooltip(true)}
                  className="ml-auto rounded-full border px-3 m-2 shadow-[0_0_5px] shadow-orange border-orange"
                >
                  i
                </p>
              </div>
              <select {...register("advice type")}>
                <option value="" disabled>
                  Select advice type
                </option>
                <option value="1">1.0</option>
                <option value="2">2.0</option>
              </select>
              <p className="flex">
                Remaining pieces of advice
                <span className="ml-auto text-white">{remaininigAdvices}/25</span>
              </p>
              <ProgressBar usedPiecesCount={remaininigAdvices} containerWidth={windowSize.width} />
              <button
                disabled={showLoaderForAdvice}
                type="submit"
                className={`${utilCl.yellow_button} text-2xl w-full mt-10 mb-7 py-4 rounded-2xl`}
              >
                Generate advice
              </button>
            </form>
            {displayedAdvice && <Advice advice={displayedAdvice} />}
          </>
        </div>
        <div
          ref={resizeRef}
          className={`${cl.resizeHandle} cursor-nwse-resize`}
          onMouseDown={startResizing}
        />
      </div>
    );
  }
);

export default DowtWindow;