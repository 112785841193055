import React from 'react';
import SpaceList from './SpaceList.tsx';
import styles from './space_list.module.scss';

const SpaceListContainer = () => {
  return (
    <div className={styles.spaceListPage}>
      <SpaceList />
    </div>
  );
};

export default SpaceListContainer;
