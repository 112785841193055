import type { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import cl from "./LoginPage.module.scss";
import bottom from "/src/image/login_p/bottom.svg";

interface IProps {
  loggedIn: boolean;
}

const LoginPage: FC<IProps> = ({ loggedIn }) => {
  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className={cl.main}>
      <div className={cl.form_container}>
        <Outlet />
      </div>
      <img src={bottom} alt="is loading" />
    </div>
  );
};

export default LoginPage;
