import { create } from "zustand";
import { createLoginSlice, type LoginSlice } from "./loginSlice";
import { createDialogSlice, type DialogSlice } from "./dialogSlice";
import { createAccountSlice, type AccountSlice } from "./AccountSlice";
import { createCategoriesSlice, type CategoriesSlice } from "./categoriesSlice";

const useStore = create<
  LoginSlice & DialogSlice & AccountSlice & CategoriesSlice
>((...a) => ({
  ...createLoginSlice(...a),
  ...createDialogSlice(...a),
  ...createAccountSlice(...a),
  ...createCategoriesSlice(...a),
}));

export default useStore;
