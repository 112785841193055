import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import classes from "./home_page.module.scss";
import Expenses from "./Expenses";
import Overlay from "./Overlay";
import { SpaceAPI } from "services/API"; // импортируйте SpaceAPI
import DOMPurify from "dompurify";
import { sanitizeObject } from "utils/functions/sanitizeObject";

interface IProps {
  loggedIn: boolean;
}

const HomePage: React.FC<IProps> = ({ loggedIn }) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Получаем параметр id из URL
  const [, setSpace] = useState(null); // состояние для хранения пространства

  useEffect(() => {
    const storedSpace = localStorage.getItem("space"); // получаем пространство из localStorage
    if (!storedSpace) {
      // если нет, получаем пространства с сервера
      SpaceAPI.getMySpaces()
        .then((spaces) => {
          if (spaces.length > 0) {
            const firstSpace = spaces[0]; // берем первое пространство
            setSpace(firstSpace); // устанавливаем первое пространство в состояние
            localStorage.setItem(
              "space",
              JSON.stringify(sanitizeObject(firstSpace))
            ); // сохраняем первое пространство в localStorage
          }
        })
        .catch((error) => {
          console.error("Error fetching spaces:", error);
        });
    } else {
      setSpace(JSON.parse(DOMPurify.sanitize(storedSpace))); // если есть, устанавливаем его в состояние
    }
  }, []);

  useEffect(() => {
    if (id) {
      // Если в URL есть идентификатор пространства, перенаправляем пользователя на домашнюю страницу
      // и передаем идентификатор пространства в компоненте Expenses
      navigate("/home", { state: { spaceId: DOMPurify.sanitize(id) } });
    }
  }, [id, navigate]);

  return (
    <div className={classes.main}>
      <Expenses /> {/* передаем пространство в Expenses */}
      <Overlay />
    </div>
  );
};

export default HomePage;
