import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import cl from "./edit_space.module.scss";
import ThreeUsersIcon from "image/spaces_p/ThreeUsersIcon.svg";
import MembersMenu from "./MembersMenu/MembersMenu";
import AutoComplete from "components/LoginPage/ChooseCurrency/AutoComplete";
import { currencies } from "utils/constants/currencies";
import { useForm } from "react-hook-form";
import ConfirmDialog from "./ConfirmDeleteDialog/ConfirmDialog"; // Import the ConfirmDialog component

const EditSpace = () => {
  const [title, setTitle] = useState("");
  const [, setCurrencyName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [canEditPermissions, setCanEditPermissions] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const { spaceId } = useParams();

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        const space = await SpaceAPI.getSpace(spaceId);
        setTitle(space.title);
        setCurrencyCode(space.currency);
        setCanEditPermissions(space.can_edit_permissions);
        setValue("currency", space.currency, { shouldValidate: true });
      } catch (error) {
        console.error("Error fetching space:", error);
      }
    };

    fetchSpace();
  }, [spaceId, setValue]);

  const onSubmit = async () => {
    try {
      await SpaceAPI.updateSpace(spaceId, { title, currency: currencyCode });
      navigate("/space");
    } catch (error) {
      console.error("Error updating space:", error);
    }
  };

  const handleCurrencyClick = (e) => {
    const selectedCurrency = e.currentTarget.innerText;
    setCurrencyName(selectedCurrency);
    setCurrencyCode(currencies[selectedCurrency]);
    setValue("currency", selectedCurrency, { shouldValidate: true });
  };

  const handleDelete = async () => {
    try {
      await SpaceAPI.deleteSpace(spaceId);
      navigate("/space");
    } catch (error) {
      console.error("Error deleting space:", error);
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setShowConfirmDialog(false);
    handleDelete();
  };

  const handleCancelDelete = () => {
    setShowConfirmDialog(false);
  };

  return (
    <div className={cl.container}>
      <div className={cl.content}>
        <div className={cl.leftSide}>
          <div className={cl.title}>
            <img src={ThreeUsersIcon} alt="it's loading" />
            <div className="w-[50vw] max-w-[15rem]">
              <p className="mb-[14px]">Edit Space</p>
              <p className="text-[15px] text-[#6E6E6E]">
                Update the title and select a currency for your space.
              </p>
            </div>
          </div>
          <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={cl.formGroup}>
              <div className={cl.titleField}>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  required
                />
              </div>
            </div>
            <div className={cl.formGroup}>
              <div className={cl.currencyInputContainer}>
                <AutoComplete
                  register={register}
                  placeholder="Currency"
                  values={Object.keys(currencies)}
                  onClickElement={handleCurrencyClick}
                />
              </div>
            </div>
            <div className={cl.buttons}>
              <button
                className="rounded-l-[12px] bg-[#181818]"
                type="button"
                onClick={() => navigate("/space")}
              >
                Back
              </button>
              <button
                className="rounded-r-[12px] bg-[#FFA800] font-bold text-[black] color-[#181818]"
                type="submit"
              >
                Update
              </button>
            </div>
            <button
              className="text-[#747474]"
              type="button"
              onClick={handleDeleteClick}
            >
              Delete Space
            </button>
          </form>
        </div>
        <MembersMenu canEditPermission={canEditPermissions} />{" "}
        {/* Передача пропса */}
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          message="Are you sure you want to delete the space?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default EditSpace;
