import React, { type Dispatch, type SetStateAction } from "react";
import { useForm } from "react-hook-form";
import type { IIncomeAccountReq } from "utils/types/request/AccountRequest";
import withLoader from "utils/hoc/withLoader";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Account/Transaction";
import TransactionsForm from "./TransactionsForm";

const TransactionsFormContainer = React.memo(
  ({
    firstInputClass,
    setModal,
    mutate,
  }: {
    firstInputClass: string;
    setModal: Dispatch<SetStateAction<boolean>>;
    mutate: (data: IIncomeAccountReq) => void;
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<IIncomeAccountReq>({
      resolver: zodResolver(valSchema),
    });
    const onSubmit = ({ amount, comment }: IIncomeAccountReq) => {
      const data: IIncomeAccountReq = { amount: Number.parseFloat(amount) };
      if (comment !== "") {
        data.comment = comment;
      }
      mutate(data);
    };
    return (
      <TransactionsForm
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        firstInputClass={firstInputClass}
      />
    );
  },
);

export default withLoader(TransactionsFormContainer);
